import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Checkbox } from '@paljs/ui/Checkbox';
import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, Timestamp, getDoc } from 'firebase/firestore';
import Auth, { Group } from '../../components/Auth';
// import Socials from '../../components/Auth/Socials';
import SEO from '../../components/SEO';
import { auth, db } from '../../utils/fb';

export default function Login(props) {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [err, setErr] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  function validateEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const isBrowser = typeof window !== 'undefined';

  useEffect(() => {
    if (isBrowser) {
      if (localStorage.checkbox && props.location.href === props.authUrl) {
        setIsChecked(true);
        setValues({ email: localStorage.email, password: localStorage.password });
      }
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setEmailErr('');
    setPasswordErr('');
    setErr('');
    if (!values.email) {
      setEmailErr('Email is required');
      return;
    }
    if (!values.password) {
      setPasswordErr('Password is required');
      return;
    }
    if (!validateEmail(values.email)) {
      setEmailErr('Please provide a valid email');
      return;
    }
    try {
      const result = await signInWithEmailAndPassword(auth, values.email, values.password);

      const docRef = await getDoc(doc(db, 'users', result.user.uid));
      if (docRef.exists()) {
        await setDoc(
          doc(db, 'users', result.user.uid),
          {
            lastLogin: Timestamp.fromDate(new Date()),
            totalLogin: docRef.data().totalLogin + 1,
          },
          { merge: true },
        );

        docRef.data().status.value === 'Active' ? navigate('/app/users/') : navigate('/disabled');
        setValues({ email: '', password: '' });
      }
      if (isChecked) {
        localStorage.email = values.email;
        localStorage.password = values.password;
        localStorage.checkbox = isChecked;
        localStorage.authUrl = 'http://localhost:8888/auth/login';
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('checkbox');
        localStorage.removeItem('authUrl');
      }
    } catch (err) {
      console.log(err.message);
      setErr(err.message);
    }
  };

  return (
    <Auth title="SOSA Instructor E-Learning" subTitle="Hello! Login with your SOSA assocaited email.">
      <SEO title="Login" />
      <form onSubmit={handleSubmit}>
        <InputGroup fullWidth>
          <input type="email" placeholder="Email Address" name="email" value={values.email} onChange={handleChange} />
        </InputGroup>
        {emailErr && <p>{emailErr}</p>}
        <InputGroup fullWidth>
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
        </InputGroup>
        {passwordErr && <p>{passwordErr}</p>}
        <Group>
          <Checkbox onChange={() => setIsChecked(!isChecked)} checked={isChecked}>
            Remember me
          </Checkbox>
          <Link to="/auth/request-password">Forgot Password?</Link>
        </Group>
        <Button status="Success" type="submit" shape="SemiRound" fullWidth>
          Login
        </Button>
        {err && <p>{err}</p>}
      </form>
      {/* <Socials /> */}
      <p>
        Don&apos;t have account? <Link to="/auth/register">Request to Register</Link>{' '}
        <span className="spaceLeft spaceRight"> | </span> Have a question? <a> Email us</a>
      </p>
    </Auth>
  );
}
